import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import { Modal, Button } from "antd"; // Importing Antd Modal
import "./slider.css"
export default function ClientSlider() {
  const [images, setImages] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    // Fetch images and descriptions from the backend
    const fetchImages = async () => {
      try {
        const response = await fetch("https://johnwayneshuttle.com/api/images");
        const data = await response.json();
        setImages(data);
      } catch (error) {
        console.error("Failed to fetch images:", error);
      }
    };

    fetchImages();
  }, []);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Show 4 images at a time by default
    slidesToScroll: 1,
    arrows: true,
    autoplay: true, // Enable automatic sliding
    autoplaySpeed: 3000, // Slide every 3 seconds
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3, // Show 3 images on tablet devices
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2, // Show 2 images on small devices
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1, // Show 1 image on very small devices
          slidesToScroll: 1,
        },
      },
    ],
  };

  const openModal = (image) => {
    setSelectedImage(image);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedImage(null);
  };

  const getFullImageUrl = (imageName) => {
    return `https://johnwayneshuttle.com/uploads/${imageName}`;
  };

  return (
    <div>
      <Slider {...settings}className="slidCont">
        {images.map((image) => (
          <LogoWrapper key={image._id} onClick={() => openModal(image)}>
            <ImgStyle src={getFullImageUrl(image.image)} alt="client logo" />
          </LogoWrapper>
        ))}
      </Slider>

      {/* Ant Design Modal for image details */}
      <Modal
        title="Image Details"
        open={modalVisible}
        onCancel={closeModal}
        footer={[
          <Button key="close" onClick={closeModal}>
            Close
          </Button>,
        ]}
      >
        {selectedImage && (
          <>
            <img
              src={getFullImageUrl(selectedImage.image)}
              alt="Selected client logo"
              style={{ width: "100%", height: "auto" }} // Responsive in the modal
            />
            <p style={{ marginTop: "20px" }}>{selectedImage.description}</p>
            <p style={{ marginTop: "20px" }}>{selectedImage.numberOfPassengers}</p>
          </>
        )}
      </Modal>
    </div>
  );
}

// Styled components
const LogoWrapper = styled.div`
  width: 100%;
  height: auto;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;

const ImgStyle = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensure the full image is visible within its container */
  aspect-ratio: 16 / 9; /* Maintain a 16:9 aspect ratio */

  @media (max-width: 1024px) {
    aspect-ratio: 16 / 9;
  }

  @media (max-width: 768px) {
    aspect-ratio: 4 / 3; /* Change to a 4:3 ratio on smaller devices */
  }

  @media (max-width: 600px) {
    aspect-ratio: 1 / 1; /* Make the image square on very small devices */
    object-fit: contain; /* Ensure the full image is visible on mobile */
  }

  @media (max-width: 480px) {
    aspect-ratio: 1 / 1; /* Maintain square ratio on very small screens */
    object-fit: contain; /* Ensure the full image is visible */
  }
`;
