import React from 'react';
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import BookingForm from './components/BookingForm';
import Payment from './components/Payment';
import Landing from './components/landingpageComponents/screens/Landing';
import AdminDashboard from './components/admin/adminDashboard/AdminDashboard';
import UserManagement from './components/admin/adminDashboard/UserManagement.jsx';
import Layouts from './components/admin/layout/Layouts';
import { useAuth } from './authcontext/AuthContext'; // Import the AuthContext

function App() {
  const { isAuthenticated, userRole } = useAuth();

  return (
    <Router>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
      </Helmet>
      <div className="App">

        <Routes>
          {/* Public route */}
          {!isAuthenticated ? (
            <>
              <Route path="/" element={<Landing />} />
              <Route path="*" element={<Navigate to="/" />} />
            </>
          ) : (
            <>
              {/* Protected routes based on authentication */}
              {userRole === 'admin' ? (
                <>
                  <Route
                    path="/admin"
                    element={<Layouts><AdminDashboard /></Layouts>}
                  />
                  <Route
                    path="/admin/users"
                    element={<Layouts><UserManagement /></Layouts>}
                  />
                  {/* Redirect admin to /admin if they try to access any invalid routes */}
                  <Route path="*" element={<Navigate to="/admin" />} />
                </>
              ) : (
                <>
                  <Route path="/" element={<Landing />} />
                  <Route
                    path="/payment/:bookingId/:amount"
                    element={<Payment />}
                  />
                  {/* Redirect non-admin users to /booking for any other invalid routes */}
                  <Route path="*" element={<Navigate to="/booking" />} />
                </>
              )}
            </>
          )}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
