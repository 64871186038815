// components/Nav/TopNavbar.jsx
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../authcontext/AuthContext"; // Import AuthContext

import { Link as ScrollLink } from 'react-scroll';

// Components
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
import BurgerIcon from "../../assets/svg/BurgerIcon";
import axios from 'axios';
import { notification } from 'antd';
import "./login.css"
import "./TopNavbar.css"
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  h2 {
    margin-bottom: 15px;
  }
  label {
    display: block;
    margin: 10px 0 5px;
  }
  input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
  }
  button {
    margin-right: 10px;
    padding: 10px 20px;
  }
`;

const Error = styled.div`
  color: red;
  margin-bottom: 10px;
`;

const Modal = ({ closeModal, isLoginForm, toggleForm, onLoginSuccess }) => {
  const [username, setUsername] = useState(''); // Use username for login
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');





  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      if (isLoginForm) {
        // Login
        const response = await axios.post('https://johnwayneshuttle.com/api/auth/login', { username, password });
        const { token, role } = response.data;

        // Save token and role to localStorage
        localStorage.setItem('token', token);
        localStorage.setItem('role', role);
        localStorage.setItem('username', username);

        // Update AuthContext
        onLoginSuccess(username, role);

        // Redirect based on role
        if (role === 'admin') {
          window.location.href = '/admin';
        } else {
          window.location.href = '/';
        }
      } else {
        // Register
        await axios.post('https://johnwayneshuttle.com/api/auth/register', { username, email, phone, password, fullName, role: 'user' });
        notification.success({ message: 'Registration successful! Please log in.' });
        toggleForm(); // Switch to login form after successful registration
      }
      closeModal(); // Close modal on success
    } catch (err) {
      setError(err.response?.data?.error || 'An error occurred');
    }
  };

  return (
    <ModalOverlay>
      <ModalContent className="login-modal-content">
        <h2>{isLoginForm ? "Login" : "Register"}</h2>
        <form onSubmit={handleSubmit}>
          {error && <Error>{error}</Error>}
          {isLoginForm ? (
            <>
              <label>Username:</label>
              <input
              className="login-input"
                type="text"
                placeholder="Enter your username"
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <label>Password:</label>
              <input
              className="login-input"
                type="password"
                placeholder="Enter your password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button type="submit" className="login-register-btn">Log In</button>
            </>
          ) : (
            <>
              <label>Username:</label>
              <input
                type="text"
                placeholder="Enter your username"
                className="login-input"
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <label>Full Name:</label>
              <input
                type="text"
                placeholder="Enter your full name"
                className="login-input"
                required
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
              <label>Email:</label>
              <input
                type="email"
                placeholder="Enter your email"
                className="login-input"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label>Phone:</label>
              <input
                type="text"
                placeholder="Enter your phone number"
                className="login-input"
                required
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <label>Password:</label>
              <input
                type="password"
                placeholder="Create a password"
                className="login-input"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button type="submit">Register</button>
            </>
          )}
          <button type="button" onClick={toggleForm} className="login-register-btn">
            {isLoginForm ? "Don't have an account? Register" : "Already have an account? Log In"}
          </button>
          <button type="button" onClick={closeModal} className="login-close-btn">
            Close
          </button>
        </form>
      </ModalContent>
    </ModalOverlay>
  );
};

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [isLoginForm, setIsLoginForm] = useState(true); // State to toggle between login/register
  const { isAuthenticated, userRole, username, login, logout } = useAuth(); // Destructure AuthContext
  const navigate = useNavigate();


  useEffect(() => {
    const handleBackButton = (e) => {
      if (isAuthenticated && window.location.pathname === '/admin' && userRole !== 'admin') {
        // Prevent user from going back to an unauthorized page
        e.preventDefault();
        window.history.forward();
      }
    };

    window.onpopstate = handleBackButton;

    return () => {
      window.onpopstate = null; // Cleanup event listener
    };
  }, [isAuthenticated, userRole]);

  useEffect(() => {
    const handleScroll = () => setY(window.scrollY);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    if (sidebarOpen) {
      toggleSidebar(false); // Close the sidebar when the modal opens
    }
  };

  const toggleForm = () => {
    setIsLoginForm(!isLoginForm); // Toggle between login and register forms
  };

  const handleLoginSuccess = (username, role) => {
    // This function can be used to perform additional actions after login
    // For example, you can navigate programmatically instead of using window.location
    navigate(role === 'admin' ? '/admin' : '/booking');
  };

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />

      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      {isModalOpen && (
        <Modal
          closeModal={toggleModal}
          isLoginForm={isLoginForm}
          toggleForm={toggleForm}
          onLoginSuccess={handleLoginSuccess}
        />
      )}


      <Wrapper className="flexCenter animate whiteBg" style={y > 100 ? { height: "60px" } : { height: "80px" }}>
      <div className="phoneon-landingpage">
            <div>
              Cell: 714-757-3249
            </div>
            <div className="office-phone">
              Office: 206-285-2818
            </div>
          </div>

        <NavInner className="container flexSpaceCenter">

          <Link className="pointer flexNullCenter" to="home" smooth={true}>
            <h1 style={{ marginLeft: "15px" }} className="font20 extraBold">
              JohnWayneshuttle
            </h1>
          </Link>
          <BurderWrapper className="pointer" onClick={() => toggleSidebar(!sidebarOpen)}>
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper className="flexNullCenter">
  <li className="semiBold font15 pointer">
    <ScrollLink
      to="home"
      spy={true}
      smooth={true}
      offset={-80}
      duration={500}
      style={{ padding: "10px 15px" }}
    >
      Home
    </ScrollLink>
  </li>
  <li className="semiBold font15 pointer">
    <ScrollLink
      to="services"
      spy={true}
      smooth={true}
      offset={200}
      duration={500}
      style={{ padding: "10px 15px" }}
    >
      Services
    </ScrollLink>
  </li>

  <li className="semiBold font15 pointer">
    <ScrollLink
      to="contact"
      spy={true}
      smooth={true}
      offset={200}
      duration={500}
      style={{ padding: "10px 15px" }}
    >
      Contact
    </ScrollLink>
  </li>
</UlWrapper>

          <UlWrapperRight className="flexNullCenter">
            {isAuthenticated ? (
              <>
                <li className="semiBold font15 pointer">
                  <span>Welcome, {username}</span>
                </li>
                <li className="semiBold font15 pointer">
                  <ButtonStyled onClick={logout}>Logout</ButtonStyled>
                </li>
              </>
            ) : (
              <li className="semiBold font15 pointer">
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleModal(); // Open modal and close sidebar
                  }}
                  style={{ padding: "10px 30px 10px 0" }}
                >
                  Log in
                </a>
              </li>
            )}
            <li className="semiBold font15 pointer flexCenter">

              <ScrollLink
      to="services"
      spy={true}
      smooth={true}
      offset={200}
      duration={500}
      style={{ padding: "10px 15px" }}
      className="radius8 lightBg"
    >
      Get Started
    </ScrollLink>

            </li>
          </UlWrapperRight>
        </NavInner>
      </Wrapper>
    </>
  );
}

// Styled Components
const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  padding: 20px;
  left: 0;
  z-index: 999;
`;

const NavInner = styled.div`
  position: relative;
  height: 100%;
`;

const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;

const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;

const UlWrapperRight = styled.ul`
  display: flex;
  align-items: center;
  @media (max-width: 760px) {
    display: none;
  }
`;

const ButtonStyled = styled.button`
  background: none;
  border: none;
  color: #1890ff;
  cursor: pointer;
  font-size: 1em;
  &:hover {
    color: #40a9ff;
  }
`;
