import React, { useEffect, useState } from 'react';
import { Layout, Table, Button, Modal, notification, Space, Input } from 'antd';
import { EyeOutlined, SearchOutlined } from '@ant-design/icons';
import "./viewBooking.css";

const { Content } = Layout;

const AdminBooking = ({ bookingId, onResetNotification }) => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [hasOpenedFromNotification, setHasOpenedFromNotification] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  useEffect(() => {
    fetchBookings();
  }, []);

  useEffect(() => {
    if (bookingId && !hasOpenedFromNotification) {
      const booking = bookings.find(b => b._id === bookingId);
      if (booking) {
        setSelectedBooking(booking);
        setIsModalVisible(true);
        setHasOpenedFromNotification(true);
        onResetNotification(bookingId);
      }
    }
  }, [bookingId, bookings, hasOpenedFromNotification, onResetNotification]);

  useEffect(() => {
    setFilteredBookings(
      bookings.filter(booking =>
        (booking?.pickupLocation?.display_name || '').toLowerCase().includes(searchText.toLowerCase()) ||
        (booking?.dropoffLocation?.display_name || '').toLowerCase().includes(searchText.toLowerCase()) ||
        (booking?.guestInfo?.name || '').toLowerCase().includes(searchText.toLowerCase())
      )
    );
    console.log(filteredBookings); // Log filtered bookings
  }, [searchText, bookings]);


  const fetchBookings = async () => {
    try {
      const response = await fetch('https://johnwayneshuttle.com/api/bookings');
      const data = await response.json();
      data.reverse();
      setBookings(data);
      setFilteredBookings(data);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch bookings:', error);
      setLoading(false);
    }
  };

  const markBookingAsViewed = async (bookingId) => {
    try {
      await fetch(`https://johnwayneshuttle.com/api/bookings/${bookingId}/viewed`, {
        method: 'PATCH', // Assuming you have a PATCH endpoint to mark as viewed
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      console.error('Failed to mark booking as viewed:', error);
    }
  };

  const handleViewDetails = async (record) => {
    setSelectedBooking(record);
    setIsModalVisible(true);
    await markBookingAsViewed(record._id);

    // Update local state to reflect viewed status
    setBookings(prevBookings => {
      const updatedBookings = prevBookings.map(booking =>
        booking._id === record._id ? { ...booking, viewed: true } : booking
      );
      console.log(updatedBookings); // Log updated bookings
      return updatedBookings;
    });
  };


  const columns = [
    {
      title: 'Service Type',
      dataIndex: 'serviceType',
      key: 'serviceType',
    },
    {
      title: 'Selected Vehicle',
      dataIndex: 'vehicleName',
      key: 'vehicleName',
    },
    {
      title: 'Guest Name',
      key: 'guestName',
      render: (text, record) => record?.guestInfo?.fullName || record?.userInfo?.fullName || 'N/A',
    },
    {
      title: 'Guest Email',
      key: 'guestEmail',
      render: (text, record) => record?.guestInfo?.email || record?.userInfo?.email || 'N/A',
    },
    {
      title: 'Guest Phone',
      key: 'guestPhone',
      render: (text, record) => record?.guestInfo?.phone || record?.userInfo?.phone || 'N/A',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <Button icon={<EyeOutlined />} onClick={() => handleViewDetails(record)}>View</Button>
        </Space>
      ),
    },
  ];

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  return (
    <div className="admin-booking">
      <Content style={{ padding: '24px', minHeight: 280 }}>
        <Input
          placeholder="Search bookings"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          prefix={<SearchOutlined />}
          style={{ marginBottom: '20px' }}
        />
        <Table
          className='bookingTable'
          columns={columns}
          dataSource={filteredBookings}
          rowKey="_id"
          loading={loading}
          pagination={{ current: currentPage, pageSize: pageSize }}
          onChange={handleTableChange}
          scroll={{ x: 800 }} // Add horizontal scroll for smaller screens
          rowClassName={(record) => record.viewed ? 'viewed-row' : 'not-viewed-row'}
        />

        <Modal
          title="Booking Details"
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          className='bookingInfoModal'
          footer={null}
          width={800}
        >
          {selectedBooking && (
            <div className='bookingInfoModalCont'>
              {/* Airport Service */}
              {selectedBooking?.serviceType === 'Airport Service' && (
                <div className='modalSection'>
                  <h3>Airport Service</h3>
                  {selectedBooking?.tripType && <p><strong>Trip Type:</strong> {selectedBooking?.tripType}</p>}
                  {selectedBooking?.departureAirport && <p><strong>Departure Airport:</strong> {selectedBooking?.departureAirport}</p>}
                  {selectedBooking?.arrivalAirport && <p><strong>Arrival Airport:</strong> {selectedBooking?.arrivalAirport}</p>}
                  {selectedBooking?.airportName && <p><strong>Selected Airport:</strong> {selectedBooking?.airportName}</p>}
                  {selectedBooking?.residence && <p><strong>Residence:</strong> {selectedBooking?.residence}</p>}
                  {selectedBooking?.passengerCount && <p><strong>Number Of passenger:</strong> {selectedBooking?.passengerCount}</p>}
                  {selectedBooking?.vehicleName && <p><strong>Selected Vehicle:</strong> {selectedBooking?.vehicleName}</p>}
                  {selectedBooking?.pickupDate && <p><strong>Pick-up Date:</strong> {new Date(selectedBooking?.pickupDate).toLocaleDateString()}</p>}
                  {selectedBooking?.pickupTime && <p><strong>Pick-up Time:</strong> {selectedBooking?.pickupTime}</p>}
                </div>
              )}

              {/* Hourly Charter */}
              {selectedBooking?.serviceType === 'Hourly Charter' && (
                <div className='modalSection'>
                  <h3>Hourly Charter</h3>
                  {selectedBooking?.tripType && <p><strong>Trip Type:</strong> {selectedBooking?.tripType}</p>}
                  {selectedBooking?.pickupLocation?.display_name && <p><strong>Pick-up Location:</strong> {selectedBooking?.pickupLocation.display_name}</p>}
                  {selectedBooking?.dropoffLocation?.display_name && <p><strong>Drop-off Location:</strong> {selectedBooking?.dropoffLocation.display_name}</p>}
                  {selectedBooking?.hours && <p><strong>Number of Hours:</strong> {selectedBooking?.hours}</p>}
                  {selectedBooking?.pickupDate && <p><strong>Pick-up Date:</strong> {new Date(selectedBooking?.pickupDate).toLocaleDateString()}</p>}
                  {selectedBooking?.pickupTime && <p><strong>Pick-up Time:</strong> {selectedBooking?.pickupTime}</p>}
                  {selectedBooking?.vehicleName && <p><strong>Selected Vehicle:</strong> {selectedBooking?.vehicleName}</p>}

                  {selectedBooking?.guestCount && <p><strong>Number of Guests:</strong> {selectedBooking?.guestCount}</p>}
                </div>
              )}

              {/* Point-to-Point Service */}
              {selectedBooking?.serviceType === 'Point to point' && (
                <div className='modalSection'>
                  <h3>Point to Point</h3>
                  {selectedBooking?.tripType && <p><strong>Trip Type:</strong> {selectedBooking?.tripType}</p>}
                  {selectedBooking?.pickupLocation?.display_name && <p><strong>PickUp Location:</strong> {selectedBooking?.pickupLocation.display_name}</p>}
                  {selectedBooking?.dropoffLocation?.display_name && <p><strong>DropOff Location:</strong> {selectedBooking?.dropoffLocation.display_name}</p>}
                  {selectedBooking?.vehicleName && <p><strong>Selected Vehicle:</strong> {selectedBooking?.vehicleName}</p>}
                  {selectedBooking?.pickupDate && <p><strong>Pick Up Date:</strong> {new Date(selectedBooking?.pickupDate).toLocaleDateString()}</p>}
                  {selectedBooking?.pickupTime && <p><strong>Pickup Time:</strong> {selectedBooking?.pickupTime}</p>}
                </div>
              )}

              {/* Fallback for unrecognized service types */}
              {selectedBooking?.serviceType !== 'Airport Service' &&
               selectedBooking?.serviceType !== 'Hourly Charter' &&
               selectedBooking?.serviceType !== 'Point to point' && (
                <p>Service details are not available for this service type.</p>
              )}

              {/* Shared Information */}
              <div className='rightModalCont'>
                <h3>General Information</h3>
                {selectedBooking?.guestInfo?.fullName || selectedBooking?.userInfo?.fullName ? (
                  <p><strong>Guest Name:</strong> {selectedBooking?.guestInfo?.fullName || selectedBooking?.userInfo?.fullName}</p>
                ) : null}

                {selectedBooking?.guestInfo?.email || selectedBooking?.userInfo?.email ? (
                  <p><strong>Guest Email:</strong> {selectedBooking?.guestInfo?.email || selectedBooking?.userInfo?.email}</p>
                ) : null}

                {selectedBooking?.guestInfo?.phone || selectedBooking?.userInfo?.phone ? (
                  <p><strong>Guest Phone:</strong> {selectedBooking?.guestInfo?.phone || selectedBooking?.userInfo?.phone}</p>
                ) : null}

                {selectedBooking?.paymentMethod && <p><strong>Payment Method:</strong> {selectedBooking?.paymentMethod}</p>}
                {selectedBooking?.paypalTransactionId && <p><strong>Transaction ID:</strong> {selectedBooking?.paypalTransactionId}</p>}
                {selectedBooking?.paypalPaymentStatus && <p><strong>Payment Status:</strong> {selectedBooking?.paypalPaymentStatus}</p>}
                {selectedBooking?.totalPrice && <p><strong>Total Price:</strong> ${selectedBooking?.totalPrice}</p>}
                {selectedBooking?.totalDistance && <p><strong>Total Distance:</strong> {selectedBooking?.totalDistance} km</p>}
              </div>
            </div>
          )}
        </Modal>
      </Content>
    </div>
  );
};

export default AdminBooking;
