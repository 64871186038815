import { PayPalButtons } from '@paypal/react-paypal-js';

function Payment({ amount, bookingId }) {
  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [{
        amount: { value: amount }
      }]
    });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      alert('Payment successful!');
    });
  };

  return (
    <PayPalButtons createOrder={createOrder} onApprove={onApprove} />
  );
}

export default Payment;
