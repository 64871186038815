import React, { useState } from 'react';
import styled from "styled-components";
import { Link } from "react-scroll";
import { Drawer} from 'antd';
import "./footer.css"
// Assets


export default function Contact() {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState('');
  const getCurrentYear = () => {
    return new Date().getFullYear();
  }
  const showDrawer = (title) => {
    setDrawerTitle(title);
    setDrawerVisible(true);
};

const closeDrawer = () => {
    setDrawerVisible(false);
};

  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container">
          <InnerWrapper className="flexSpaceCenter" style={{ padding: "30px 0" }}>
            <Link className="flexCenter animate pointer" to="home" smooth={true} offset={-80}>
              {/* <LogoImg /> */}
              <h1 className="font15 extraBold whiteColor" style={{ marginLeft: "15px" }}>
                JohnWayneshuttle
              </h1>
            </Link>
            <StyleP className="whiteColor font13 developer-info">
              © {getCurrentYear()} - <span className="purpleColor font13" onClick={() => showDrawer('Developers Information')}> Byte Artisans.</span> All Right Reserved
            </StyleP>

            <Link className="whiteColor animate pointer font13" to="home" smooth={true} offset={-80}>
              Back to top
            </Link>
          </InnerWrapper>
        </div>
      </div>
      <Drawer
                title={drawerTitle}
                placement="right"
                onClose={closeDrawer}
                open={drawerVisible}
            >
                {drawerTitle === 'Developers Information' ? (
                    <div className='drawer-contents'>
                        <div className='dev-info'>
                            <p>Name: Daniel Shobe</p>
                            <p>Phone: +251 949 0528 48</p>
                            <p>Email: danielshobe90@gmail.com</p>
                            <p>GitHub: <a href="https://github.com/moheranus" target="_blank" rel="noopener noreferrer">github.com/moheranus</a></p>
                        </div>
                        <div className='dev-info'>
                            <p>Name: Nebyat Ahmed</p>
                            <p>Phone: +251 934 6617 61</p>
                            <p>Email: nebyatAhmed21@gmail.com</p>
                            <p>GitHub: <a href="https://github.com/Afiya21" target="_blank" rel="noopener noreferrer">github.com/Afiya21</a></p>
                        </div>
                    </div>
                ) : (
                    <div>
                        <h3>About Us</h3>
                        <p>Welcome to our rideshare service! We are dedicated to providing you with a safe, reliable, and comfortable ride to your destination. Our team of professional drivers is committed to ensuring that you have the best experience possible.</p>

                    </div>
                )}
            </Drawer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;