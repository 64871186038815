import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import CloseIcon from "../../assets/svg/CloseIcon";
import axios from "axios";

const Modal = ({ closeModal, isLoginForm, toggleForm }) => {
  const [username, setUsername] = useState(""); // Replacing email with username
  const [password, setPassword] = useState("");
  const [name, setName] = useState(""); // Only needed for registration
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const endpoint = isLoginForm
      ? "https://johnwayneshuttle.com/api/auth/login"
      : "https://johnwayneshuttle.com/api/auth/register";

    const payload = isLoginForm
      ? { username, password }
      : { name, username, password };

    try {
      const response = await axios.post(endpoint, payload);
      const { token, role } = response.data;

      // Store JWT token and role in localStorage
      localStorage.setItem("token", token);
      localStorage.setItem("role", role);

      // Redirect based on user role
      if (role === "admin") {
        window.location.href = "/admin"; // Redirect to admin dashboard
      } else {
        window.location.href = "/"; // Redirect to user
      }

      closeModal(); // Close modal after success
    } catch (error) {
      console.error("Authentication failed", error);
      alert("Authentication failed, please try again.");
    } finally {
      setLoading(false);
    }
  };


  return (
    <ModalOverlay>
      <ModalContent className="login-modal-content">
        <h2>{isLoginForm ? "Login" : "Register"}</h2>
        <form onSubmit={handleSubmit}>
          {!isLoginForm && (
            <>
              <label>Full Name:</label>
              <input
                type="text"
                className="login-input"
                placeholder="Enter your full name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </>
          )}
          <label>Username:</label> {/* Changed label to Username */}
          <input
            type="text" // Changed input type to text for username
            placeholder="Enter your username"
            className="login-input"
            value={username}
            onChange={(e) => setUsername(e.target.value)} // Replaced setEmail with setUsername
            required
          />
          <label>Password:</label>
          <input
            type="password"
            placeholder="Enter your password"
            className="login-input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit" disabled={loading}>
            {loading ? "Loading..." : isLoginForm ? "Log In" : "Register"}
          </button>
          <button type="button" onClick={toggleForm} className="login-register-btn">
            {isLoginForm ? "Don't have an account? Register" : "Already have an account? Log In"}
          </button>
          <button type="button" onClick={closeModal} className="login-close-btn">
            Close
          </button>
        </form>
      </ModalContent>
    </ModalOverlay>
  );
};

export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoginForm, setIsLoginForm] = useState(true); // State to toggle between login/register
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Check if user is authenticated on component mount
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    if (sidebarOpen) {
      toggleSidebar(false); // Close the sidebar when the modal opens
    }
  };

  const toggleForm = () => {
    setIsLoginForm(!isLoginForm); // Toggle between login and register forms
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    setIsAuthenticated(false);
    window.location.href = "/"; // Redirect to the homepage after logout
  };


  return (
    <>
      <Wrapper className="animate darkBg" sidebarOpen={sidebarOpen}>
        <SidebarHeader className="flexSpaceCenter">
          <div className="flexNullCenter">

            <h1 className="whiteColor font20" style={{ marginLeft: "15px" }}>
              JohnWayneshuttle
            </h1>
          </div>
          <CloseBtn onClick={() => toggleSidebar(!sidebarOpen)} className="animate pointer">
            <CloseIcon />
          </CloseBtn>
        </SidebarHeader>

        <UlStyle className="flexNullCenter flexColumn">
          <li className="semiBold font15 pointer">
            <Link
              onClick={() => toggleSidebar(!sidebarOpen)}
              activeClass="active"
              className="whiteColor"
              style={{ padding: "10px 15px" }}
              to="home"
              spy={true}
              smooth={true}
              offset={-60}
            >
              Home
            </Link>
          </li>
          <li className="semiBold font15 pointer">
            <Link
              onClick={() => toggleSidebar(!sidebarOpen)}
              activeClass="active"
              className="whiteColor"
              style={{ padding: "10px 15px" }}
              to="services"
              spy={true}
              smooth={true}
              offset={250}
            >
              Services
            </Link>
          </li>

          <li className="semiBold font15 pointer">
            <Link
              onClick={() => toggleSidebar(!sidebarOpen)}
              activeClass="active"
              className="whiteColor"
              style={{ padding: "10px 15px" }}
              to="contact"
              spy={true}
              smooth={true}
              offset={200}
            >
              Contact
            </Link>
          </li>
        </UlStyle>

        <UlStyle className="flexSpaceCenter">
  {!isAuthenticated ? (
    <li className="semiBold font15 pointer">
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          toggleModal(); // Show login/register modal
        }}
        style={{ padding: "10px 30px 10px 0" }}
        className="whiteColor"
      >
        Log in
      </a>
    </li>
  ) : (
    <li className="semiBold font15 pointer">
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          handleLogout(); // Log out user
        }}
        style={{ padding: "10px 30px 10px 0" }}
        className="whiteColor"
      >
        Log out
      </a>
    </li>
  )}
  <li className="semiBold font15 pointer flexCenter">


      <Link
              onClick={() => toggleSidebar(!sidebarOpen)}
              activeClass="active"
              className="radius8 lightBg"
              style={{ padding: "10px 15px" }}
              to="services"
              spy={true}
              smooth={true}
              offset={320}
            >
              Get Started
            </Link>

  </li>
</UlStyle>

      </Wrapper>

      {isModalOpen && <Modal closeModal={toggleModal} isLoginForm={isLoginForm} toggleForm={toggleForm} />}
    </>
  );
}

// Styled Components
const Wrapper = styled.nav`
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-400px")};
  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
  }
`;

const SidebarHeader = styled.div`
  padding: 20px 0;
`;

const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;

const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  h2 {
    margin-bottom: 15px;
  }
  label {
    display: block;
    margin: 10px 0 5px;
  }
  input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
  }
  button {
    margin-right: 10px;
    padding: 10px 20px;
  }
`;
