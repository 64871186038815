import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const token = localStorage.getItem('token');
    return !!token;
  });
  const [userRole, setUserRole] = useState(localStorage.getItem('role') || null);
  const [username, setUsername] = useState(localStorage.getItem('username') || null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setIsAuthenticated(true);
        setUserRole(decoded.role);
        setUsername(decoded.username); // Ensure this is in the token
      } catch (error) {
        console.error('Token decode error:', error);
        logout(); // Clear auth state if token is invalid
      }
    }
  }, []);

  const login = async (username, password) => {
    try {
      const response = await axios.post('https://johnwayneshuttle.com/api/auth/login', { username, password });
      const { token, role } = response.data;

      localStorage.setItem('token', token); // Store token
      localStorage.setItem('role', role);   // Store role

      setIsAuthenticated(true);
      setUserRole(role);
      setUsername(username);
      return { username, role };
    } catch (error) {
      const errorMsg = error.response?.data?.message || 'Login failed';
      console.error('Login error:', errorMsg);
      throw new Error(errorMsg);
    }
  };


  const register = async (username, email, password) => {
    try {
      await axios.post('https://johnwayneshuttle.com/api/auth/register', { username, email, password });
    } catch (error) {
      const errorMsg = error.response?.data?.message || 'Registration failed';
      console.error('Registration error:', errorMsg);
      throw new Error(errorMsg);
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('username');
    setIsAuthenticated(false);
    setUserRole(null);
    setUsername(null);
  };

  return (
    <AuthContext.Provider value={{ login, register, isAuthenticated, userRole, logout, username }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
